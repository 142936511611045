<template>
  <div class='itemScroll_container'  ref='itemScrollList'>
    <div class="itemScroll_content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import BScroll  from 'better-scroll'
export default {
  name:'itemScroll',
  props:{
    isTop:{
      type:Boolean,
      default:false
    }
  },
  created(){
    this.initScroll()
  },
  methods:{
    initScroll(){
      this.$nextTick(()=>{
        if(!this.scroll){
          this.scroll = new BScroll(this.$refs.itemScrollList,{
            click:true,
            mouseWheel:true,
            useTransition:false
          })
          this.scroll.on("refresh",()=>{
            if(this.isTop) this.scrollTo(0)
          })
        }else{
          this.scroll.refresh();
        }
      })
    },
    getScrollAttr(key){
      return this.scroll[key]
    },
    scrollTo(scrollHeight,time){
      if(time)
        this.scroll.scrollTo(0,scrollHeight,time,{
          fn:(number)=>{ return number }
        })
      else{
        this.scroll.scrollTo(0,scrollHeight)
      }
    }
  }
}
</script>
<style>
.itemScroll_container{
  height:100%;
  width:100%;
  position: relative;
  overflow: hidden;
}
</style>